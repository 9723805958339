html,
body {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  @apply text-text bg-white;
}

// main {
//   transform: translateZ(0);
// }

@import "flickity.scss";

.wrapper {
  width: 100%;
  margin: 0 auto;
  // max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
  }
  @media (min-width: 1200px) {
    padding: 0 100px;
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-text {
  font-size: responsive 30px 40px;
  line-height: responsive 28px 35px;
}

h1,
.h1 {
  font-size: responsive 30px 60px;
  line-height: responsive 34px 64px;
}

.large-intro {
  p {
    font-size: responsive 20px 40px;
    font-weight: 200;
    font-style: italic;
  }
}
#main-header {
  iframe {
    border: none;
    overflow: hidden;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#mc_embed_signup {
  background: #fff;
  clear: left;
  width: 100%;
  max-width: 600px;
}
.full-intro {
  p {
    font-size: responsive 18px 20px;
    font-weight: 200;
    font-style: italic;
  }
}

.header-description {
  font-size: responsive 17px 22px !important;
  line-height: responsive 22px 27px !important;
}

@mixin card($cols, $gutter) {
  margin-right: $gutter;
  width: calc(percentage(1 / $cols) - ($gutter - ($gutter / $cols)));
}

.media-slider__card {
  @include card(1, 20px);
  @media (min-width: 500px) {
    @include card(2, 20px);
  }
  @media (min-width: 900px) {
    @include card(3, 20px);
  }
  @media (min-width: 1100px) {
    @include card(4, 20px);
  }
}

.media-slider__nav {
  @apply m-1 my-5 flex lg:ml-5;
  button {
    width: 70px;
    height: 70px;
    background-image: url(/assets/left-arrow.svg);
    background-size: 100%;
    transition: all 0.2s ease-out;
    border-radius: 100%;
    &:hover {
      background-color: #7ab7bc;
    }
  }
  button:last-child {
    @apply ml-1;
    background-image: url(/assets/right-arrow.svg);
  }
}

.slide-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1180px + 40px);
  padding: 0 0px 0 20px;
  @media (min-width: 800px) {
    padding: 0 0px 0 100px;
    max-width: calc(1180px + 120px);
  }

  button.flickity-button.flickity-prev-next-button.previous {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50px;
    bottom: 0px;
    top: auto;
    background-image: url(/assets/left-arrow.svg);
  }
  button.flickity-button.flickity-prev-next-button.next {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 140px;
    top: auto;
    bottom: 0px;
    background-image: url(/assets/right-arrow.svg);
  }
}
h2,
.h2 {
  font-size: 30px;
  @include letter-spacing(-0.3);
  font-weight: 300;
  @apply mb-8;
}

h3,
.h3 {
  font-weight: 300;
  font-size: 25px;
  @include letter-spacing(-0.3);
}

h4,
.h4 {
  font-size: 15px;
  @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-size: 15px;
  line-height: 1.64;
  @include letter-spacing(-0.3);
  margin-bottom: 1.7em;
  &:last-of-type {
    margin-bottom: 0;
  }
}
strong {
  font-weight: 700;
}
.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}
.header-text {
  p {
    font-size: responsive 20px 25px;
    line-height: responsive 28px 33px;
  }
}
.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .burger-text {
    display: none;
  }
  .mob-logo {
    display: block !important;
  }

  .big-logo {
    display: none !important;
  }

  .nav-button {
    height: 50px !important;
    width: 100px !important;
  }
  height: 80px;
  background-color: #fff;
  .nav-links {
    padding-top: 0;
  }
  @media (max-width: 768px) {
    padding-right: 90px !important;
  }

  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 40px !important;
  padding-right: 40px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 35px !important;
    width: auto !important;
  }
  .book-btn {
    padding: 19px 17px !important;
    @media (max-width: 768px) {
      padding: 15px 13px !important;
    }
  }
  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    background-color: #ffffff;
    height: 70px !important;
    padding-right: 0px !important;
    padding-left: 10px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 30px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
}

.headroom--top {
  .burger-text {
    display: grid;
    transition: all ease-in-out 2s;
    @media (max-width: 768px) {
      display: none !important;
    }
  }
  .book-btn {
    @media (max-width: 768px) {
      padding: 17px 15px !important;
    }
    padding: 15px 30px !important;
  }
  .mob-logo {
    display: none !important;
  }
  .big-logo {
    display: block !important;
  }
  .nav-button {
    height: 50px !important;
    width: 127px !important;
  }
  filter: none;
  height: 80px;
  background-color: #fff;
  box-shadow: none;
  display: grid;
  .logo {
    height: 35px !important;
    width: 100% !important;
  }

  @media (max-width: 1280px) {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    height: 80px !important;
    padding-right: 20px !important;
    @media (max-width: 768px) {
      padding-right: 0px !important;
    }

    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 35px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  padding-left: 140px !important;
  padding-right: 40px !important;

  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}

.stats {
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    .div-1,
    .div-2,
    .div-3,
    .div-4 {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 600px) {
    display: block;
  }
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 45px;
  grid-row-gap: 0px;
  margin-top: 60px;
}

.div-1 {
  grid-area: 1 / 1 / 2 / 2;
  @media (max-width: 1024px) {
    grid-area: 1 / 1 / 2 / 2;
  }
}
.div-2 {
  grid-area: 1 / 2 / 2 / 3;
  @media (max-width: 1024px) {
    grid-area: 1 / 2 / 2 / 3;
  }
}
.div-3 {
  grid-area: 1 / 3 / 2 / 4;
  @media (max-width: 1024px) {
    grid-area: 2 / 1 / 3 / 2;
  }
}
.div-4 {
  grid-area: 1 / 4 / 2 / 5;
  @media (max-width: 1024px) {
    grid-area: 2 / 2 / 3 / 3;
  }
}
.carousel-main {
  outline: none !important;
  transition: all 0.4s ease-in-out;
  .flickity-slider {
    transform: none !important;
  }
}

.carousel-main:hover {
  cursor: pointer;
}

.gallery-cell:focus {
  outline: none !important;
}

.gallery-cell {
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  transform: none !important;
}

.gallery-cell.is-selected {
  opacity: 1;
  z-index: 0;
}

button.flickity-button.flickity-prev-next-button.previous:hover {
  background-color: #7ab7bc;
  transition: all 0.2s ease-in-out;
}
button.flickity-button.flickity-prev-next-button.next:hover {
  background-color: #7ab7bc;
  transition: all 0.2s ease-in-out;
}

button.flickity-button.flickity-prev-next-button.previous {
  width: 70px;
  transition: all 0.2s ease-in-out;
  height: 70px;
  position: absolute;
  border-radius: 100%;
  right: 76px;
  top: 240px;
  background-image: url(/assets/left-arrow.svg);
}
button.flickity-button.flickity-prev-next-button.next {
  width: 70px;
  transition: all 0.2s ease-in-out;
  height: 70px;
  position: absolute;
  right: 0;
  border-radius: 100%;
  top: 240px;
  background-image: url(/assets/right-arrow.svg);
}
.flickity-button-icon {
  display: none;
}

.newsletter {
  background-image: url(/assets/newsletter-bg.jpg);
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}
.description {
  h3 {
    margin-bottom: 1.7em;
  }
  ul {
    margin-top: 40px;
    list-style: none;
    width: 100%;
    li {
      width: calc(50% - 40px);
      display: inline-block;
      padding-left: 40px;
      margin-bottom: 15px;
      position: relative;
      @media (max-width: 600px) {
        width: calc(100% - 40px);
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-image: url(/assets/tick-li.svg);
        background-size: 100%;
      }
    }
  }
}

.custom-bullets {
  h3 {
    margin-bottom: 1.7em;
  }
  ul {
    list-style: none;
    width: 100%;
    li {
      display: inline-block;
      padding-left: 40px;
      margin-bottom: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-image: url(/assets/tick-li.svg);
        background-size: 100%;
      }
    }
  }
}

.footer-contact {
  background-image: url(/assets/white-ripples.jpg);
  background-size: cover;
}
.foot-nav {
  li {
    display: inline-block;
  }
}

.gallery-slider {
  height: 700px;
}

.table-cont {
  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #d8d8d8;
        td {
          min-width: 100px !important;
          padding: 15px 0;
          text-align: center;
        }
        td:first-child {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #d8d8d8;
        td {
          @media (max-width: 900px) {
            min-width: 100px !important;
          }
          padding: 15px 0;
          text-align: center;
        }
        td:first-child {
          text-align: left;
        }
      }
    }
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

input::placeholder,
textarea::placeholder {
  color: black !important;
  font-size: 14px !important;
}

.elip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

button.accordion {
  cursor: pointer;
  transition: background-color 0.2s linear;
}

button.accordion:after {
  transition: all;
  transition-duration: 1s;
  font-weight: 900;
  content: "[...Read More]";
  font-style: italic;
}

button.accordion.is-open:after {
  transition: all;
  transition-duration: 1s;
  p {
    padding-top: 200px;
    transition: all;
    transition-duration: 1s;
    background-color: white !important;
  }
  content: "";
  font-style: italic;
  position: absolute;
  z-index: 800;
  padding: 20px;
  .acc-prev {
    display: none !important;
  }
}

.accordion-content {
  z-index: 400;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}
.wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  th,
  td {
    text-align: left;
    padding-bottom: 0.75em;
    padding-right: 2em;
    vertical-align: top;
    min-width: 200px;
    white-space: nowrap;
    &:last-child {
      padding-right: 0;
    }
  }
  table {
    @extend .text-xs;
    width: 100%;
  }

  & > article {
    @media (max-width: 600px) {
      padding-bottom: 80px;
      margin-bottom: 80px;
    }

    padding-bottom: 130px;
    margin-bottom: 130px;
    border-bottom: 1px solid #313131;
  }
}

.table-wrap {
  overflow-x: auto;
  max-width: 100%;
  margin: 40px 0;
  &:last-child {
    margin-bottom: 0;
  }
}

[data-article-nav] {
  a {
    text-decoration: none;
    display: block;
    padding: 0.1em 0;
    transition: all 0.2s ease-out;
    position: relative;
  }

  a.active {
    padding-left: 20px;
    font-weight: 600;
    &:before {
      position: absolute;
      width: 50px;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      content: "";
      background-color: currentColor;
    }
  }
}

.doc-filter {
  @apply flex flex-wrap my-10 justify-center;

  button {
    @apply m-5 py-2 px-3 rounded transition-all;
  }

  button.active {
    background-color: #7ab7bc;
    color: white;
  }
}

.intro-block {
  & + section.project-list,
  & + .two-col-info {
    padding-top: 0;
  }
}
.full-width-image + .intro-block {
  padding-top: 0;
}

.list {
  // ol,
  // ul {
  //   padding-left: 25px;
  // }

  ol li,
  ul li {
    @extend .p;
    list-style: number;
    margin: 10px 0;
  }
  ul li {
    list-style: disc;
  }
}

.gradient-bg {
  background: rgb(42, 96, 104);
  background: linear-gradient(
    25deg,
    rgba(42, 96, 104, 1) 0%,
    rgba(186, 224, 229, 1) 100%
  );
}

.doc:nth-of-type(2n) {
  background-color: #f5f5f5;
}
.image-lib {
  summary:after {
    content: "View more";
    margin-top: 10px;
  }

  details[open] summary:after {
    display: none !important;
  }

  details[open] summary {
    display: none !important;
  }

  details > summary {
    list-style: none;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
}

.invest-accord {
  summary:after {
    content: "+";
  }

  details[open] summary:after {
    content: "-";
  }

  details[open] summary {
    content: "-";
    background-color: #dfdfdf;
  }

  details {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  details[open] {
    background-color: #eeeeee9c;
    padding-bottom: 40px;
  }

  summary[open] {
    background-color: #d3d3d3;
    padding-bottom: 40px;
  }

  summary {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  summary:hover {
    cursor: pointer !important;
    transition: ease-in-out 300ms;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
  a {
    text-decoration: none;
    padding: 10px 30px;
    margin-top: 3px;
    background-color: #044f58;
    color: white;
  }
}

.header-links-flex {
  @media (min-width: 1240px) {
    display: flex !important;
    .px-eight {
      margin-left: 32px;
      margin-right: 32px;
    }
  }
}

.dropdown-menu {
  summary:hover {
    details {
      display: block !important;
    }
  }

  details[open] {
    padding: 0.5em;
  }

  details[open] summary {
    margin-bottom: 0.5em;
  }
  .pmore {
    content: "+";
  }
}
