@import "tailwindcss/base";

@import "css/index.scss";
@import "css/animations.scss";
@import "css/lightbox.scss";

@import "tailwindcss/components";
@import "tailwindcss/utilities";

.oh {
  overflow: hidden;
}

hr {
  border-color: #160638;
  transform: scaleY(0.5);
}

.featured .flickity-viewport {
  overflow: visible;
}

::selection {
  background: #68aaae;
  color: white;
}

.col2 {
  @media (min-width: 700px) {
    column-count: 2;
    column-gap: 40px;
  }
}

@keyframes price {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.price-open {
  animation: price 0.3s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.filter-btn.selected {
  background-color: #ffe000 !important;
  color: #313131 !important;
}

.filter-btn:nth-child(1) {
  background-color: #daecee;
}
.filter-btn:nth-child(2) {
  background-color: #bae0e5;
}
.filter-btn:nth-child(3) {
  background-color: #68aaae;
}
.filter-btn:nth-child(4) {
  background-color: #2e7178;
}
.filter-btn:nth-child(5) {
  background-color: #043f47;
}

.filter-btn {
  color: white;
}

.newsletter-signup input::placeholder {
  color: white !important;
}
